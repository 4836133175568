body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* OnBoaring Page */

.link {
  cursor: pointer;
}
.root {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
}
.warningBar {
  max-height: 2.5625rem;
  width: 50%;
  height: 100%;
  padding: 12px 0px;
  gap: 8px;
  background: rgba(255, 54, 105, 0.3);
}
.b-container {
  gap: 1.875rem;
}
.dangerTxt {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  /* identical to box height */

  /* Negative Color */

  color: #ff3669;
}

.txt {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;

  color: #0d1c2e;
}

/* onBoarding Input */

.cardBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2.25rem;
  gap: 2.25rem;
  border: none;
  width: 34%;

  /* width: 647.33px;
  height: 396px; */

  background: #fafbff;
  /* effect */

  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}
.headingtxt {
  font-family: "Inter", sans-serif;

  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  /* identical to box height */

  color: #0d1c2e;
}

.form-label {
  font-family: "Inter", sans-serif;

  font-style: normal;
  font-size: 0.875rem;
  line-height: 17px;
  /* identical to box height */

  color: #0d1c2e;
}
.form-control-input {
  padding: 0.5rem 0.675rem !important;
  gap: 0.5rem;
  background: #ffffff;
  border: 1px solid #e8e8ea;
  border-radius: 7px !important;
}
.form-control-input:hover {
  border: 1px solid #25cbc9 !important;
  box-shadow: none !important;
}
.form-control-input:active {
  border: 1px solid #25cbc9 !important;
  box-shadow: 0 0 0 1px #25cbc9 !important;
}

.customButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.675rem 2rem !important;
  gap: 0.625rem;
  border: none !important;
  background: #25cbc9 !important;
  border-radius: 0.5rem !important;
}
.labeltxt {
  font-family: "Inter", sans-serif;

  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  /* identical to box height */

  color: #0d1c2e;
}
.copyRighttxt {
  font-family: "Inter", sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  /* identical to box height */

  /* text secondary */

  color: #868d97;
}
.containerPatient {
  padding: 2.25rem 6.25rem !important;
}

.breadcrumbactive {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  /* identical to box height */

  color: #25cbc9;
  white-space: nowrap;
}

.breadcrumbinactive {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  color: #868d97;
}

.formInput {
  flex: 1 1 auto !important;
}

.widthAuto {
  width: auto !important;
}
.secondaryBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.675rem 2rem !important;
  gap: 0.625rem;
  border: none !important;
  border-radius: 0.5rem !important;
}

.ReactModal__Content {
  width: 70%;
  height: 500px;
  min-height: 450px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.lightxt {
  font-family: "Inter", sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  /* identical to box height */

  /* text secondary */

  color: #868d97;
}
.smalltxt {
  font-family: "Inter", sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  /* identical to box height */

  color: #0d1c2e;
}
.imgBox {
  display: none;
}
.headingContainer {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

/* Media Queries */

@media screen and (max-width: 1440px) {
  html,
  body {
    font-size: 15px;
  }
}
@media screen and (max-width: 1280px) {
  html,
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: 1080px) {
  html,
  body {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  html,
  body {
    font-size: 10px;
  }
}

@media screen and (max-width: 320px) {
  html,
  body {
    font-size: 9px;
  }
}

/* onBoarding Screen Start */

@media screen and (max-width: 1440px) {
  .warningBar {
    width: 60%;
  }
  .cardBox {
    width: 40%;
  }
}
@media screen and (max-width: 1080px) {
  .warningBar {
    width: 70%;
  }
  .cardBox {
    width: 45%;
  }
}
@media screen and (max-width: 980px) {
  .cardBox {
    width: 55%;
  }
}
@media screen and (max-width: 768px) {
  .warningBar {
    width: 80%;
  }
  .cardBox {
    width: 80%;
  }
  .copyRighttxt {
    text-align: center;
  }
  .headingtxt {
    text-align: center;
  }
  .imgBox {
    display: block;
  }
  .headingContainer {
    justify-content: center;
    align-items: center;
  }
  .breadcrumbinactive {
    display: none;
  }
  .containerPatient {
    padding: 1.25rem 3.25rem !important;
  }
}

/* onBoarding Screen End */

/* Patient Information Page */

/* Home Page */
.navBar {
  height: 50px;
  background-color: #000;
}
.skewDiv {
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
  box-shadow: 0 3px 3px rgb(0 0 0 / 5%);
  background-color: #18a689;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: RobotoDraft, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase !important;
  font-weight: bold !important;
  color: #fff !important;
  font-size: 18px;
  cursor: pointer;
}
.navMenuText {
  color: white;
  font-size: 0.875rem;
}
.navBox {
  gap: 0.375rem;
  position: relative;
  cursor: pointer;
}
.navContainer {
  padding: 0.675rem 1.25rem;
  gap: 3.25rem;
  position: relative;
}
.mainNavBox {
  justify-content: space-between;
  position: relative;
}
.rightNavMenu {
  align-items: center;
  gap: 1.875rem;
  margin-right: 1.875rem;
}
.userImg {
  display: flex;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
}

.flagImg {
  display: flex;
  width: 1.875rem;
  height: 1.25rem;
}
.dropdown {
  position: relative;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  right: 0;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 0.675rem 0.875rem;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #18a689;
  color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.popoverBox {
  right: 0;
  width: 270px;
  transform: translateX(20px);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  position: absolute !important;
  -webkit-box-shadow: 3px 3px 0 rgb(0 0 0 / 3%);
  -moz-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 3px 3px 0 rgb(0 0 0 / 3%);
}

.popover-arrow {
  right: 0;
  left: -5px !important;
  position: absolute;
}
.imgBoxPopover {
  border-radius: 50%;
  border: 2px solid #18a689;
  padding: 0.25rem;
}
.userImgPopover {
  display: flex;
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
}
.txtName {
  font-weight: bold;
  font-family: "Inter", sans-serif;
}
.detailsBoxpopover {
  background-color: #f2f2f2;
  padding: 0.675rem;
  display: flex;
  flex-direction: column;
}

.popOverBtn {
  font-family: "Inter", sans-serif;

  background-color: #d0dae1;
  border: none;
  display: flex;
  color: #616c79;
  gap: 0.25rem;
  text-transform: uppercase;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
}

.navBox:hover .menuDropdown {
  display: flex;
}
.menuDropdown {
  display: none;
  background-color: #18a689;
  z-index: 9999;
  color: white;
  position: absolute;
  gap: 0.275rem;
  top: 0;
  flex-direction: column;
  transform: translateY(38px);
  max-height: 600px;
  overflow-y: auto;
}

.menuDropdown:hover {
  display: flex;
}

.menuDropdown a:hover {
  background-color: #067760;
  color: white;
}
.subMenuTxt {
  padding: 0.5rem 0.875rem;
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
}
.detailsContainer {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: #fff;
}
.imgDetailsBox {
  border-radius: 10px;
  border: 2px solid #18a689;
  padding: 0.125rem;
  height: fit-content;
}
.userDetailsImage {
  display: flex;
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 10px;
}
.detailsTxt {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
}
.subDetailsconatiner {
  gap: 1.875rem;
}
.home {
  background-color: #eaeaea;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
.btnDetailBox {
  border: none;
  background-color: #18a689;
  padding: 0.45rem 2.5rem;
  display: flex;
  color: #fff;
  gap: 0.25rem;
  font-size: 0.675rem;
  text-transform: uppercase;
  font-weight: 500;
  max-width: 280px;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  border-radius: 30px;
  /* white-space: nowrap; */
}
.flex1 {
  flex: 1;
}
.customSearchInputDetails {
  border: 2px solid #999;
  border-radius: 10px 0px 0px 10px !important;
  padding: 0 !important;
  padding-left: 2px !important;
  font-size: 13px !important;
  background-size: 0 2px, 100% 0px;
  background-color: #fff;
  color: #333 !important;
  font-weight: 400 !important;

  height: 34px;
  max-width: 160px;
  width: 100%;
}
.customSearchInputDetails:focus {
  background-color: #d2d2d2;

  background-size: 100% 2px, 100% 1px;
  outline: 0 !important;
  background-image: linear-gradient(#0097a7, #0097a7),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none !important;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  transition: all 0.2s ease-out;
}
.iconBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-radius: 3px;
  padding: 0.5rem 0.675rem;
  background-color: #18a689;
  color: #ffffff !important;
  box-shadow: none;
  font-size: 11px;
  white-space: nowrap;
  gap: 0.25rem;
  font-weight: bold;
  text-transform: uppercase;
}
.yellowBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-radius: 10px;
  padding: 0.5rem 0.675rem;
  background-color: #fac240 !important;
  color: #585858;
  box-shadow: none;
  font-size: 0.875rem;
  white-space: nowrap;
  gap: 0.25rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.2s ease,
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0 !important;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.memoRankInput {
  border: 1px solid rgb(172, 169, 169);
  border-radius: 5px;
  padding: 0.5em 0.875rem;
  font-size: 13px !important;
  background-color: #fff;
  color: #333 !important;
  font-weight: 400 !important;
  width: 100%;
}
.memoRankInput:focus {
  background-color: #d2d2d2;

  background-size: 100% 2px, 100% 1px;
  outline: 0 !important;
  background-image: linear-gradient(#0097a7, #0097a7),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none !important;
  border: none;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  transition: all 0.2s ease-out;
}
.content {
  padding: 0rem 1.25rem;
}

.tabBtn {
  background-color: white !important;
  border-bottom: none;
  color: #000 !important;
  display: block;
  padding: 0.125rem 0.875rem;
  border-radius: 0 !important;
  width: fit-content;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border: none !important;
}
.tabContainer {
  background-color: white;
  border-top: 2px solid #18a689;
  border-bottom-left-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
  padding: 0.975rem;
}
.horizontalLine {
  width: 100%;
  color: #18a689;
  height: 2px;
  background-color: #18a689;
}
.MuiTableRow-root {
  height: 90px !important;
}
.MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}
.sideBox {
  border-top: 2px solid #18a689;
  padding: 0.945rem;
  gap: 1rem;
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.sideContainer {
  flex-basis: 20%;
  width: 100%;
}
.tableBoxCotainer {
  flex: 1;
  flex-basis: 70%;
}
.sideTxt {
  font-size: 0.875rem;
  font-family: "Inter", sans-serif;
}
.FooterBox {
  border-top: 2px solid #18a689;
  padding: 0.945rem;
  gap: 1rem;
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: 40px;
  margin-bottom: 10rem;
}

/* Patient Login */
.loginRootContainer {
  background-color: #2f455c;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
}

.cardPatientLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.25rem;
  gap: 2.25rem;
  border: none;
  width: 34%;
}
.cardRadius {
  background: #fafbff;
  box-shadow: 0px 4px 40px rgb(0 0 0 / 5%);
  border-radius: 1rem;
  width: 35%;
}

.cardWithBg {
  background: #fff;
  box-shadow: 0px 4px 40px rgb(0 0 0 / 5%);
  border-radius: 1rem;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* Patient list */

.tabButton {
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) !important;
  border-radius: 1.5rem !important;
  display: flex;
  padding: 0.615rem 1.25rem !important;
  font-size: 0.625rem;
}
.tabButton:hover{
    background-color: #2f455c !important;
      color: white !important;
}
.activebtn{
  background-color: #2f455c !important;
  color: white;
}
.inactivebtn{
  background-color: #fff !important;
  color: #000 !important;
}
.nav-link.active {
  background: none !important;
}

.inputTable {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
 padding: 6px 8px ;
  color: #000;
}
.cardD{
  background-color: #fff;
  border-radius: none !important;
}
.borderGreenBox{
  background: #FFFFFF;
    border-top: 1px solid #21D0B3;
    border-bottom: 1px solid #21D0B3;
    border-left: 1px solid #21D0B3;
    font-style: normal;
    font-weight: 500;
    font-size: 0.675rem;
    display: flex;
    align-items: center;
  
    color: #2F455C;
}
.borderGreenBoxlast{
    border-right: 1px solid #21D0B3;
}